export function maskSensitiveFields(jsonObject: any): any {
    const maskChar = "*";
    const listOfKeysToMask = ["temporary_token", "expiry_month", "expiry_year", "card_security_code", "card_token", "session", "account_number", "routing-number", "card_number"];
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        return jsonObject;
    }
    const maskedData = { ...jsonObject };

    for (const key in maskedData) {
        if (listOfKeysToMask.includes(key) && typeof maskedData[key] === 'string') {
            maskedData[key] = maskChar.repeat(maskedData[key].length);
        } else if (typeof maskedData[key] === 'object') {
            maskedData[key] = maskSensitiveFields(maskedData[key]);
        }

    }
    return maskedData;
}