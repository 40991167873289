import { IError } from "../gateways";
import getGateway from "../lib/get-gateway";

export default async (
  merchantId: string,
  requestId: string,
  storeDisplayName: string
) => {
  const gateway = getGateway();

  if (merchantId === undefined) {
    return Promise.reject({
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "no merchantIdentifier found",
        },
      ],
    });
  }

  if (storeDisplayName === undefined) {
    return Promise.reject({
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "no storeDisplayName found",
        },
      ],
    });
  }

  if (!gateway) {
    return Promise.reject({
      error: true,
      reasons: [
        { code: "INVALID_CONFIGURATION", message: "no gateway available" },
      ],
    });
  }

  if (!gateway.actions.validateMerchant) {
    return Promise.reject({
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "gateway does not support merchant validation",
        },
      ],
    });
  }

  if (!gateway.actions.normalizeValidationResponse) {
    return Promise.reject({
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "normalization not provided for gateway",
        },
      ],
    });
  }

  const resp = await gateway.actions.validateMerchant(
    merchantId,
    requestId,
    storeDisplayName
  );
  const normalizedResp = await gateway.actions.normalizeValidationResponse(
    resp,
  );

  if ((normalizedResp as IError).error) {
    return Promise.reject(normalizedResp);
  }

  try {
    const session = JSON.parse(window.atob(normalizedResp.session));
    return session;
  } catch (e) {
    return Promise.reject({
      error: true,
      reasons: [{ code: e.name, message: e.message }],
    });
  }
};
