import getGateway from "../../lib/get-gateway";
import { options } from "../../lib/options";
import { addToLog, submitLogs } from "../../logger";

export default async (
  merchantId: string,
  requestId: string,
  storeDisplayName: string
) => {
  const gateway = getGateway();
  if (gateway) {
    const request: any = {
      attributes: {
        domain_name: location.hostname,
        merchant_identifier: merchantId,
        store_display_name: storeDisplayName
      },
      type: "apple-pay",
    };

    let environment = gateway.getEnv(options);
    environment = environment !== "local" ? environment : "dev";

    const merchantValidationApiUrl = gateway.merchantValidationApiUrls![
      environment
    ];
    const startTime = new Date();

    addToLog({
      what: {
        proxy_url: merchantValidationApiUrl,
        request_payload: request,
        x_gp_request_id: requestId,
      },
      when: {
        target_start_time: startTime,
      },
      where: {
        env: environment,
      },
    });

    try {
      const headerKey = "Authorization";
      const authorizationHeader = options[headerKey]
      const headers = {
        "Content-Type": "application/json",
        "X-GP-Api-Key": options["X-GP-Api-Key"],
        "X-GP-Request-Id": requestId,
        "X-GP-Version": "2020-05-07",
        "X-GP-Merchant-Key": options["X-GP-Merchant-Key"],
        "Authorization": authorizationHeader,
      };
      const resp = await fetch(merchantValidationApiUrl, {
        body: JSON.stringify(request),
        credentials: "omit",
        headers:
          typeof Headers !== "undefined" ? new Headers(headers) : headers,
        method: "POST",
      });
      const parsedResponse = await resp.json();
      const endTime = new Date();

      addToLog({
        what: {
          response_payload: parsedResponse,
        },
        when: {
          target_end_time: endTime,
          total_processing_time: +endTime - +startTime,
        },
      });
      submitLogs();
      return parsedResponse;
    } catch (e) {
      const endTime = new Date();
      addToLog({
        what: {
          error_message: e.message,
          internal_log_error_message: e.name,
          log_level: "error",
        },
        when: {
          target_end_time: endTime,
          total_processing_time: +endTime - +startTime,
        },
      });
      submitLogs();
      return {
        error: { code: e.name, message: e.message },
      };
    }
  }
};
