import { getEnv } from "../internal/gateways/openedge";
import getGateway from "../internal/lib/get-gateway";
import { options } from "../internal";
export function defaultInstruments() {
  return [
    {
      supportedMethods: "https://apple.com/apple-pay", data: {
        version: 3,
        merchantIdentifier: "merchant.com.paygateway.pqa.web",
        merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
        supportedNetworks: ["amex", "discover", "masterCard", "visa"],
        countryCode: "US",
        storeDisplayName: "test"
      }
    },
    {
      supportedMethods: "https://google.com/pay", data: {
        environment: 'TEST',
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo: {
          // A merchant ID is available after approval by Google.
          // @see {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist}
          // merchantId: '12345678901234567890',
          merchantName: 'Example Merchant'
        },
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            // Check with your payment gateway on the parameters to pass.
            // @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway}
            parameters: {
              'gateway': 'globalpaymentsinc',
              'gatewayMerchantId': 'googletest'
            }
          }
        }]
      }
    }
  ];
}

export function defaultDetails() {
  return {} as PaymentDetailsInit;
}

export function defaultOptions() {
  return {};
}

export function applyDefaultApplePaySettings(instruments: PaymentMethodData[]) {
  const applePayInstrument = instruments.find(
    (instrument) =>
      instrument.supportedMethods === "https://apple.com/apple-pay",
  );

  if (!applePayInstrument) {
    return;
  }

  if (!applePayInstrument.data) {
    applePayInstrument.data = {};
  }

  applePayInstrument.data.countryCode = applePayInstrument.data.countryCode || "US";
  applePayInstrument.data.merchantCapabilities = applePayInstrument.data.merchantCapabilities || ["supports3DS", "supportsCredit", "supportsDebit"];
  applePayInstrument.data.supportedNetworks = applePayInstrument.data.supportedNetworks || ["amex", "discover", "masterCard", "visa"];
  applePayInstrument.data.version = 3;
}

export function applyDefaultGooglePaySettings(instruments: PaymentMethodData[]) {


  const googlePayInstrument = instruments.find(
    (instrument) =>
      instrument.supportedMethods === "https://google.com/pay",
  );



  if (!googlePayInstrument) {
    return;
  }

  if (!googlePayInstrument.data) {
    googlePayInstrument.data = {};
  }
  googlePayInstrument.data.environment = googlePayInstrument.data.environment || 'PRODUCTION';
  googlePayInstrument.data.apiVersion = googlePayInstrument.data.apiVersion || 2;
  googlePayInstrument.data.apiVersionMinor = googlePayInstrument.data.apiVersionMinor || 0;

  if (!googlePayInstrument.data.allowedPaymentMethods) {
    googlePayInstrument.data.allowedPaymentMethods = {};
  }

  googlePayInstrument.data.allowedPaymentMethods.type = googlePayInstrument.data.allowedPaymentMethods.type || 'CARD';

  if (!googlePayInstrument.data.allowedPaymentMethods) {
    googlePayInstrument.data.allowedPaymentMethods = [{
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        // Check with your payment gateway on the parameters to pass.
        // @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway}
        parameters: {
          'gateway': 'globalpaymentsinc',
          'gatewayMerchantId': 'googletest'
        }
      }
    }];

  };
}
