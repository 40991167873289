import { loadedFrames } from "../internal";
import { paymentRequestResponse } from "../internal/lib/payment-request-response";

export default (status: PaymentComplete) => {
  const frames = loadedFrames;
  for (const frameId in frames) {
    if (!frames.hasOwnProperty(frameId)) {
      continue;
    }

    if (frames[frameId]?.frame.name !== "payment-request") {
      continue;
    }

    const paymentResponse = (paymentRequestResponse as any).response as PaymentResponse;

    if (!paymentResponse) {
      const error = {
        code: "ERROR",
        message: "Missing PaymentResponse object",
      };
      /* tslint:disable:no-string-literal */
      frames[frameId]!.events["error"].forEach((cb) => {
        // @ts-ignore
        cb(error);
      });
      return;
    }

    paymentResponse
      .complete(status)
      .catch((e: Error) => {
        const error = {
          code: "ERROR",
          message: e.message,
        };
        /* tslint:disable:no-string-literal */
        frames[frameId]!.events["error"].forEach((cb) => {
          // @ts-ignore
          cb(error);
        });
      });
  }
};
