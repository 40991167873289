import version from "../../lib/version";
import { maskSensitiveFields } from './loggerUtil';
const kibanaLogsGatherer: { [key: string]: { [key: string]: any } } = {
  what: {
    app_version: version,
    log_level: "info",
    x_gp_request_id: "",
  },
  when: {},
  where: {
    env: "",
  },
  who: {
    user_agent: navigator.userAgent,
  },
};

const loggerApiKeys: { [key: string]: string } = {
  dev: "JrfPB08i85PGWkTqb9ODfh8RC2PhOS5l",
  local: "JrfPB08i85PGWkTqb9ODfh8RC2PhOS5l",
  pqa: "JrfPB08i85PGWkTqb9ODfh8RC2PhOS5l",
  prod: "heLIfA4LDoJDMgAE5bnSyPSpYvqjGGom",
  qa: "G8zacDLxy2PxiFXXy7MQOG10oiGKol1x",
  test: "ltIsgyjgE9BIaB9dwiFufERE3Rhtnn34",
};

const loggerApiUrls: { [key: string]: string } = {
  dev: "https://api.dev.paygateway.com/logger/logs",
  local: "https://api.dev.paygateway.com/logger/logs",
  pqa: "https://api.dev.paygateway.com/logger/logs",
  prod: "https://api.paygateway.com/logger/logs",
  qa: "https://api.qa.paygateway.com/logger/logs",
  test: "https://api.pit.paygateway.com/logger/logs",
};

export function addToLog(data: { [key: string]: object }) {
  Object.keys(kibanaLogsGatherer).forEach((key) => {
    if (data[key] !== undefined) {
      kibanaLogsGatherer[key] = { ...kibanaLogsGatherer[key], ...data[key] };
    }
  });
}
const prepareKibanaBody = (kibanaLogs: any) => {
  function stringify(input: any) {
    if (input instanceof Date) {
      return +input;
    }
    if (typeof input === "object") {
      return JSON.stringify(input);
    }
    if (typeof input === "undefined") {
      return "undefined";
    }
    return input.toString();
  }
  const logRequest: any = {};
  const maskedWhatLogs =  maskSensitiveFields(kibanaLogs.what);
  function get_log(logData: any) {
    const logEntry: any[] = [];
    for (const key of Object.keys(logData)) {
      const entry: any = {};
      entry.label = key;
      entry.value = stringify(logData[key]);
      logEntry.push(entry);
    }
    return logEntry;
  }

  logRequest.what = get_log(maskedWhatLogs);
  logRequest.when = get_log(kibanaLogs.when);
  logRequest.where = get_log(kibanaLogs.where);
  logRequest.who = get_log(kibanaLogs.who);
  return JSON.stringify(logRequest);
};

export function submitLogs(
  appName = "payfields",
  requestId = kibanaLogsGatherer.what.x_gp_request_id,
  apiKey = loggerApiKeys[kibanaLogsGatherer.where.env],
  loggerUrl = loggerApiUrls[kibanaLogsGatherer.where.env],
) {
  const payload = prepareKibanaBody(kibanaLogsGatherer);
  fetch(loggerUrl, {
    body: payload,
    headers: {
      "Content-Type": "application/json",
      "X-GP-Api-Key": apiKey,
      "X-GP-Request-Id": requestId,
      "X-GP-Source-App-Name": appName,
      "X-GP-Source-App-Version": version,
      "X-GP-Version": "2019-01-30",
    },
    method: "POST",
    mode: "cors",
  }).catch(() => {
    // TODO: What shall we do if logging failed?
  });
}
