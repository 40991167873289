export default async (
    gatewayMerchantId: string | undefined,
    merchantKey: string | undefined
): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!gatewayMerchantId) {
            return reject({
                error: true,
                reasons: [
                    {
                        code: "INVALID_CONFIGURATION",
                        message: "gatewayMerchantId is required",
                    },
                ],
            });
        }

        if (!merchantKey) {
            return reject({
                error: true,
                reasons: [
                    {
                        code: "INVALID_CONFIGURATION",
                        message: "X-GP-Merchant-Key is required",
                    },
                ],
            });
        }

        if (gatewayMerchantId !== merchantKey) {
            return reject({
                error: true,
                reasons: [
                    {
                        code: "INVALID_CONFIGURATION",
                        message: "gatewayMerchantId and X-GP-Merchant-Key do not match",
                    },
                ],
            });
        }
        resolve();
    });
};