import getGateway from "../../lib/get-gateway";
import getRequestId from "../../lib/get-request-id";
import { options } from "../../lib/options";
import { IDictionary } from "../../lib/util";
import { addToLog, submitLogs } from "../../logger";

export default async (url: string, data: IDictionary) => {
  const request: any = {};

  if (data["card-number"]) {
    request.card = request.card || {};
    request.card.card_number = data["card-number"].replace(/\s+/g, "");
  }

  if (data["card-cvv"]) {
    request.card = request.card || {};
    request.card.card_security_code = data["card-cvv"];
  }

  if (
    data["card-expiration"] &&
    data["card-expiration"].indexOf(" / ") !== -1
  ) {
    const exp = data["card-expiration"].split(" / ");
    request.card = request.card || {};
    request.card.expiry_month = exp[0] || "";
    request.card.expiry_year = exp[1].slice(-2) || "";
  }

  // TODO: Properly accept encrypted track data
  if (data["card-track"]) {
    request.card = request.card || {};
    request.card.track_method = "swipe";
    request.card.track = data["card-track"];
  }

  if (data["account-number"]) {
    request.ach = request.ach || {};
    request.ach.account_number = data["account-number"];
  }

  if (data["routing-number"]) {
    request.ach = request.ach || {};
    request.ach.routing_number = data["routing-number"];
  }

  const requestId = getRequestId();
  const gateway = getGateway();

  let environment = gateway ? gateway.getEnv(options) : undefined;
  const startTime = new Date();

  addToLog({
    what: {
      proxy_url: url,
      request_payload: request,
      x_gp_request_id: requestId,
    },
    when: {
      target_start_time: startTime,
    },
    where: {
      env: environment,
    },
  });

  try {
    environment = environment !== "local" ? environment : "dev";
    const headers = {
      "Content-Type": "application/json",
      "X-GP-Api-Key": options["X-GP-Api-Key"],
      "X-GP-Environment": `${environment}`,
      "X-GP-Request-Id": requestId,
      "X-GP-Version": "2019-08-22",
    };
    const resp = await fetch(url, {
      body: JSON.stringify(request),
      credentials: "omit",
      headers: typeof Headers !== "undefined" ? new Headers(headers) : headers,
      method: "POST",
    });
    const parsedResponse = await resp.json();
    const endTime = new Date();

    addToLog({
      what: {
        response_payload: parsedResponse,
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    return parsedResponse;
  } catch (e) {
    const endTime = new Date();
    addToLog({
      what: {
        error_message: e.message,
        internal_log_error_message: e.name,
        log_level: "error",
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    // TODO: needed confirmation
    return {
      error: { code: e.name, message: e.message },
    };
  }
};
