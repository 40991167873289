import { postMessage as pm } from "../../internal/lib/post-message";
import { json2css } from "../../internal/lib/styles";
import getACFUrl from "../../internal/requests/get-acf-url";
import { IframeField } from "../iframe-field";

export default async function startACFPaySession(
  payload: any,
) {
  try {
    const acfResponse = await getACFUrl(payload);
    const acfUrl = (acfResponse as any).loan_application_launch_url;

    const target = document.querySelector("#paymentRequestLoanButton") as HTMLElement;
    const el = document.createElement("div");
    el.id = "iframeholder";
    target.appendChild(el);

    const field = createURLFrames(acfUrl, target.toString(), payload);
    return new Promise((resolve) => {
      pm.receive((data: any) => {
        removeElementAllChildNodes("iframeholder");
        resolve(data);
      });
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export function removeElementAllChildNodes(id: string) {
  const element = document.getElementById(id);
  if (element) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    if (element.parentNode) {
      element.parentNode.removeChild(element);
    }
  }
}

export function createURLFrames(url: string, target: string, payload: any) {
  const field = new IframeField(
    "submit",
    "#iframeholder",
    url,
  );
  addStylesheet(
    json2css(payload.styleOptions),
    field.frame.id,
  );
  return field;
}

export function addStylesheet(css: string, id: string) {

  css = css.replace("iframe", id);
  css = css.replaceAll("=", "\\=");
  const el = document.createElement("style");
  const elements = document.getElementsByTagName("head");
  if (id) {
    if (document.getElementById(id)) {
      // return;
    }

    el.id = id;
  }
  el.type = "text/css";
  if ((el as any).styleSheet) {
    // for IE
    (el as any).styleSheet.cssText = css;
  } else {
    el.appendChild(document.createTextNode(css));
  }
  if (elements && elements[0]) {
    elements[0].appendChild(el);
  }
}
