import getGateway from "../../lib/get-gateway";
import getRequestId from "../../lib/get-request-id";
import { options } from "../../lib/options";
import { IDictionary } from "../../lib/util";
import { addToLog, submitLogs } from "../../logger";

export default async (url: string, data: IDictionary) => {
    const request: any = {};

    /* tslint:disable:no-string-literal */
    // if (data["merchant_key"]) {
    //   request.merchant_key = data["merchant_key"];
    // }
    if (data.loan["amount"]) {
        request.loan = request.loan || {};
        request.loan.amount = data.loan["amount"];
    }
    if (data.loan["currency_code"]) {
      request.loan = request.loan || {};
      request.loan.currency_code = data.loan["currency_code"];
    }
    if (data.loan["country_code"]) {
      request.loan = request.loan || {};
      request.loan.country_code = data.loan["country_code"];
    }
    if (data.loan["language"]) {
      request.loan = request.loan || {};
      request.loan.language = data.loan["language"];
    }
    if (data.customer) {
      request.customer = request.customer || {};
      request.customer = data.customer;
    }
    /* tslint:enable:no-string-literal */

    const requestId = getRequestId();
    const gateway = getGateway();
    let getACFURLS;
    let environment;
    if (gateway) {
      environment = gateway.getEnv(options);
      environment = environment !== "local" ? environment : "dev";
      getACFURLS = gateway.getACFURLS![
        environment
      ];
    }
    const startTime = new Date();

    addToLog({
        what: {
          proxy_url: url,
          request_payload: request,
          x_gp_request_id: requestId,
        },
        when: {
          target_start_time: startTime,
        },
        where: {
          env: environment,
        },
      });

    try {
    environment = environment !== "local" ? environment : "dev";
    const headers = {
      "Content-Type": "application/json",
      "X-GP-Api-Key": options["X-GP-Api-Key"],
      // "X-GP-Environment": `${environment}`,
       /* tslint:disable:no-string-literal */
      "X-GP-Merchant-Key" : data["merchant_key"],
      /* tslint:enable:no-string-literal */
      "X-GP-Request-Id": requestId,
      "X-GP-Version": "2021-08-12",
    };
    const resp = await fetch(getACFURLS, {
      body: JSON.stringify(request),
      credentials: "omit",
      headers: typeof Headers !== "undefined" ? new Headers(headers) : headers,
      method: "POST",
    });
    const parsedResponse = await resp.json();
    const endTime = new Date();

    addToLog({
      what: {
        response_payload: parsedResponse,
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    return parsedResponse;
  } catch (e) {
    const endTime = new Date();
    addToLog({
      what: {
        error_message: e.message,
        internal_log_error_message: e.name,
        log_level: "error",
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    // TODO: needed confirmation
    return {
      error: { code: e.name, message: e.message },
    };
  }
};
