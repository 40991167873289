import { IActions } from "..";
import { options } from "../../lib/options";
import { IDictionary } from "../../lib/util";
import actionGetAcfUrl from "./action-get-acf-url";

import actionNormalizeResponse from "./action-normalize-response";
import actionNormalizeValidationResponse from "./action-normalize-validation-response";
import actionTokenize from "./action-tokenize";
import actionTokenizeDigitalWallet from "./action-tokenize-digital-wallet";
import actionValidateData from "./action-validate-data";
import actionValidateMerchant from "./action-validate-merchant";

export const supports = {
  apm: {
    androidPay: false,
    applePay: true,
  },
  consumerAuthentication: true,
  eCheck: true,
  gift: true,
  tokenization: {
    cardNotPresent: true,
    cardPresent: true,
    eCheck: true,
    gift: true,
  },
};
/* tslint:disable:object-literal-sort-keys */
const domains: IDictionary = {
  local: "https://api-sandbox.dev.paygateway.com",
  dev: "https://api.dev.paygateway.com",
  pqa: "https://api.dev.paygateway.com",
  qa: "https://api.qa.paygateway.com",
  test: "https://api.pit.paygateway.com",
  prod: "https://api.paygateway.com",
};
/* tslint:enable:object-literal-sort-keys */

/* tslint:disable:object-literal-sort-keys */
export const merchantValidationApiUrls: IDictionary = {
  local: "https://api.dev.paygateway.com/digital_payments/sessions",
  dev: "https://api.dev.paygateway.com/digital_payments/sessions",
  pqa: "https://api.dev.paygateway.com/digital_payments/sessions",
  qa: "https://api.qa.paygateway.com/digital_payments/sessions",
  test: "https://api.pit.paygateway.com/digital_payments/sessions",
  prod: "https://api.paygateway.com/digital_payments/sessions",
};
/* tslint:enable:object-literal-sort-keys */

/* tslint:disable:object-literal-sort-keys */
export const getACFURLS: IDictionary = {
  local: "https://api.dev.paygateway.com/loans/applications/sessions",
  dev: "https://api.dev.paygateway.com/loans/applications/sessions",
  pqa: "https://api.dev.paygateway.com/loans/applications/sessions",
  qa: "https://api.qa.paygateway.com/loans/applications/sessions",
  test: "https://api.pit.paygateway.com/loans/applications/sessions",
  prod: "https://api.paygateway.com/loans/applications/sessions",
};
/* tslint:enable:object-literal-sort-keys */

export const urls = {
  tokenization: (prod: boolean) =>
    `${domains[options["X-GP-Environment"]]}/tokenization/temporary_tokens`,
};

export const actions: IActions = {
  getACFUrl: actionGetAcfUrl,
  normalizeResponse: actionNormalizeResponse,
  normalizeValidationResponse: actionNormalizeValidationResponse,
  tokenize: actionTokenize,
  tokenizeDigitalWallet: actionTokenizeDigitalWallet,
  validateData: actionValidateData,
  validateMerchant: actionValidateMerchant,
};

export const requiredSettings = ["X-GP-Api-Key", "X-GP-Environment"];

export const getEnv = () => {
  return options["X-GP-Environment"] || "local";
};
