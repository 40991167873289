import assetBaseUrl from "../internal/lib/asset-base-url";
import Events from "../internal/lib/events";
import { postMessage } from "../internal/lib/post-message";
import EventEmitter, { IEventListener } from "../lib/event-emitter";
import startACFPaySession from "../ui/acf";
import { IframeField } from "../ui/iframe-field";

import { bus } from "../internal";
import { addStylesheet, json2css } from "../internal/lib/styles";
import UIForm, { fieldStyles, IUIFormOptions, parentStyles } from "../ui/form";

const iframeHolderId = "global-pay-payment-acf-request";

export class PaymentACFRequestEmitter extends EventEmitter {
  private iframe: IframeField;

  constructor(iframe: IframeField) {
    super();
    this.iframe = iframe;
  }

  public on(event: string, listener: IEventListener) {
    this.iframe.on(event, listener);
  }
}
interface ILoan {
  country_code: string;
  language: string;
  amount: string;
  currency_code: string;
}

interface ICustomer {
  [key: string]: any;
}

interface IStyleOptions {
  [key: string]: any;
}

interface IMerchantKey {
  merchantKey: string;
}

export function acfform(
  selector: string,
  merchantKey: IMerchantKey,
  loan: ILoan,
  customer: ICustomer,
  styleOptions: IStyleOptions,
  startOnLoad = false,
  ) {
  const payload = Object.assign({}, merchantKey, loan, customer, styleOptions);
  const target = document.querySelector(selector);
  if (!target) {
    return bus.emit("error", {
      error: true,
      reasons: [
        { code: "INVALID_CONFIGURATION", message: "Invalid target element" },
      ],
    });
  }

  const holder = document.createElement("div");
  holder.id = iframeHolderId;
  holder.style.display = "none";
  const parent = target.parentElement;
  if (!parent) {
    return bus.emit("error", {
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "Target element has no parent",
        },
      ],
    });
  }

  parent.appendChild(holder);

  // remove the inline display style to reveal
  // @ts-ignore
  (target as HTMLElement).style.display = null;
  const iframe = new IframeField(
    "payment-acf",
    "#" + holder.id,
    assetBaseUrl() + "field.html",
  );

  const result = new PaymentACFRequestEmitter(iframe);

  const startACFPay = () => {
    startACFPaySession(payload)
      .then((data) => {
        // @ts-ignore
        if (data.event === "failure") {
          iframe.events["gpi-acf-error"].forEach((cb) => {
            // @ts-ignore
            cb(data);
          });
        } else {
          iframe.events["gpi-acf-success"].forEach((cb) => {
            // @ts-ignore
            cb(data);
          });
        }
      })
      .catch((error) => {
        iframe.events["gpi-acf-error"].forEach((cb) => {
          // @ts-ignore
          cb(error);
        });
      });
  };

  Events.addHandler(target, "click", (e: Event) => {
      e.preventDefault();
      // @ts-ignore
      startACFPay();
      return false;
    });

  iframe.on("gpi-acf-success", (data?: object) => {
    if (startOnLoad) {
      reset(holder);
    }
    result.emit("gpi-acf-success", data);
  });
  iframe.on("gpi-acf-error", (data?: object) => {
    if (startOnLoad) {
      reset(holder);
    }
    result.emit("gpi-acf-error", data);
  });
  iframe.on("error", (e?: object) => {
    if (startOnLoad) {
      reset(holder);
    }
    result.emit("error", {
      error: true,
      reasons: [e],
    });
  });
  return result;

}

const reset = (el: HTMLDivElement) => {
  if (el.remove) {
    el.remove();
  } else if (el.parentNode && el.parentNode.removeChild) {
    el.parentNode.removeChild(el);
  }
};
