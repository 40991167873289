import getGateway from "../../lib/get-gateway";
import { options } from "../../lib/options";
import { IDictionary } from "../../lib/util";
import { addToLog, submitLogs } from "../../logger";

export default async (url: string, data: IDictionary, requestId: string) => {

  let request : any = {};
  // @ts-ignore
  if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
    request = {
      digital_wallet: {
        card_token: window.btoa(JSON.stringify(data.details.token)),
        type: "apple-pay",
      },
    };
  } else {
    request = {
      digital_wallet: {
        card_token: window.btoa(data.details.paymentMethodData.tokenizationData.token),
        type: "google-pay",
      },
    };
  }

  const gateway = getGateway();
  let environment = gateway ? gateway.getEnv(options) : undefined;
  const startTime = new Date();

  addToLog({
    what: {
      proxy_url: url,
      request_payload: request,
      x_gp_request_id: requestId,
    },
    when: {
      target_start_time: startTime,
    },
    where: {
      env: environment,
    },
  });

  try {
    environment = environment !== "local" ? environment : "dev";
    const headers = {
      "Content-Type": "application/json",
      "X-GP-Api-Key": options["X-GP-Api-Key"],
      "X-GP-Environment": `${environment}`,
      "X-GP-Request-Id": requestId,
      "X-GP-Version": "2023-08-22",
      "X-GP-Merchant-Key": options["X-GP-Merchant-Key"]

    };
    const resp = await fetch(url, {
      body: JSON.stringify(request),
      credentials: "omit",
      headers: typeof Headers !== "undefined" ? new Headers(headers) : headers,
      method: "POST",
    });
    const parsedResponse = await resp.json();
    const endTime = new Date();

    addToLog({
      what: {
        response_payload: parsedResponse,
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    return parsedResponse;
  } catch (e) {
    const endTime = new Date();
    addToLog({
      what: {
        error_message: e.message,
        internal_log_error_message: e.name,
        log_level: "error",
      },
      when: {
        target_end_time: endTime,
        total_processing_time: +endTime - +startTime,
      },
    });
    submitLogs();
    // TODO: needed confirmation
    return {
      error: { code: e.name, message: e.message },
    };
  }
};
