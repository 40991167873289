import { IError, ISuccess } from "../gateways";
import buildUrl from "../lib/build-tokenization-url";
import { typeByNumber, typeByTrack } from "../lib/card-types";
import getGateway from "../lib/get-gateway";
import { options } from "../lib/options";
import { IDictionary } from "../lib/util";

export default (data: IDictionary) => {
  const gateway = getGateway();

  if (!gateway) {
    return Promise.reject({
      error: true,
      reasons: [
        { code: "INVALID_CONFIGURATION", message: "no gateway available" },
      ],
    });
  }
  return new Promise((resolve, reject) => {
    let query: any;
    if (gateway.requiredSettings.indexOf("publicApiKey") !== -1) {
      query = {
        api_key: options.publicApiKey,
      };
    }

    if (!gateway.actions.getACFUrl) {
        return Promise.reject({
          error: true,
          reasons: [
            {
              code: "INVALID_CONFIGURATION",
              message: "gateway does not support acf pay session",
            },
          ],
        });
      }

    gateway.actions.getACFUrl(buildUrl(query), data)
      .then(gateway.actions.normalizeResponse)
      .then((resp: IError | ISuccess) => {
        if ((resp as IError).error) {
          reject(resp);
          return;
        }

        resp = resp as ISuccess;
        if (gateway.requiredSettings.indexOf("X-GP-Api-Key") !== -1) {
          resolve(resp);
          return;
        }
        resolve(resp);
      })
      .catch(reject);
  });
};
